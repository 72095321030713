import React, { useEffect, useState } from "react";
import "./packageDetail.scss";
import {
  Typography,
  Button,
  Paper,
  Link,
} from "../../../material-ui/mui-components";
import {
  Container,
  styled,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Breadcrumbs,
  Grid,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { PackageDetails } from "../../../service/apiService";
import SubscriptionModalComponent from "../../security/subcription-modal/SubcriptionModal";
import StorageService from "../../../service/StorageService";
import userImage from "../../../assets/images/users/user1.jpg";

const useStyles = styled((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  videoContainer: {
    width: "100%",
    borderRadius: "5px",
    overflow: "hidden",
    marginBottom: theme.spacing(2),
  },
  ratingBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(2),
  },
  starIcon: {
    cursor: "pointer",
  },
  pointsList: {
    paddingLeft: theme.spacing(2),
  },
}));

const PackageDetailComponent = () => {
  const classes = useStyles();
  const [rating] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();

  let { state } = useLocation();
  state = state !== null ? state : "";
  //   let { packageDetails } = state;
  const [packageDetails, setPackageDetails] = useState(state);

  const organization = StorageService.getLocalItem("organization");

  useEffect(() => {
    const getPackageDetails = async () => {
      // Fetch data from API
      const industryId = organization?.industry?.id,
        vendorId = 1,
        countryId = 1; // remove once id comes from package list
      try {
        const result = await PackageDetails(industryId, vendorId, countryId);
        setPackageDetails(
          state.packageDetails ? state.packageDetails : result[0]
        );
      } catch (error) {
        console.error("There was an error fetching package details", error);
      }
    };

    getPackageDetails();
  }, []);

  const handleStarClick = (starValue) => {
    // Send API request to save rating to backend
    // For demonstration, just console log the selected rating
    //setRating(starValue);
  };
  const getBasicPrice = (plans) => {
    const basicPlan = plans?.find((plan) => plan.name === "Basic");
    let basicPrice = basicPlan
      ? basicPlan.price
      : plans?.length > 0
      ? plans[0].price
      : 0;
    if (basicPlan) {
      basicPrice = basicPlan.price;
    }
    return basicPrice;
  };

  const handleOpenSubscribeModal = () => {
    setModalData(packageDetails);
    StorageService.setLocalItem("subscribeDetails", packageDetails);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    StorageService.removeLocalItem("subscribeDetails");
    setModalOpen(false);
  };

  return (
    <div className="content-wrapper">
      <Container className={classes.root} sx={{ mb: 2, mt: 2 }}>
        <Typography align="center" variant="h4" gutterBottom sx={{ mb: 4 }}>
          {packageDetails?.title}
        </Typography>

        <div className={classes.header}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
            <div>
              <Breadcrumbs
                sx={{ mb: 2 }}
                aria-label="breadcrumb"
                className={classes.breadcrumb}
              >
                <Link color="inherit" href="/packages">
                  Packages
                </Link>
                <Typography color="textPrimary">
                  {packageDetails?.title}
                </Typography>
              </Breadcrumbs>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#4caf50" }}>
                Price: ${getBasicPrice(packageDetails?.plans)}
              </span>{" "}
              |
              <Button
                color="primary"
                onClick={() => handleOpenSubscribeModal(packageDetails)}
                sx={{ fontWeight: "bold" }}
              >
                Subscribe
              </Button>{" "}
              |
              <Button
                color="primary"
                component="a"
                href="/customize"
                sx={{ fontWeight: "bold" }}
              >
                Customize
              </Button>
            </div>
          </Box>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {packageDetails.creatorProfile ? (
              <Box display="flex" alignItems="center">
                <Avatar
                  alt={packageDetails.creatorProfile.firstName}
                  src={
                    packageDetails.creatorProfile.imageUrl
                      ? packageDetails.creatorProfile.imageUrl
                      : userImage
                  }
                  sx={{ width: 30, height: 30, marginRight: 1 }}
                />
                <Typography variant="body2" color="textPrimary">
                  {packageDetails.creatorProfile.firstName}{" "}
                  {packageDetails.creatorProfile.lastName}
                </Typography>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            {packageDetails.freelancers &&
            packageDetails.freelancers.length > 0 ? (
              <AvatarGroup max={5}>
                {packageDetails.freelancers.map((item, index) => {
                  return <Avatar src={item.imageUrl} key={index} />;
                })}
              </AvatarGroup>
            ) : null}
          </Grid>
        </Grid>
        <div className={classes.content}>
          <div
            dangerouslySetInnerHTML={{ __html: packageDetails?.description }}
          ></div>
        </div>
        <Paper sx={{ mb: 4 }}>
          <div className={classes.videoContainer}>
            {packageDetails?.videoUrl && (
              <video
                src={packageDetails?.videoUrl}
                type="video/*"
                controls
                width="100%"
                height="450px"
              ></video>
            )}
          </div>
        </Paper>
        <div
          dangerouslySetInnerHTML={{ __html: packageDetails?.description1 }}
        ></div>

        <Paper sx={{ mb: 4 }}>
          <div className="ratingBox">
            <div className={classes.ratingBox}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "auto",
                }}
              >
                <Typography variant="h5">
                  This service is offered by {packageDetails?.title}
                </Typography>
                <div>
                  {[1, 2, 3, 4, 5].map((star) =>
                    star <= rating ? (
                      <StarIcon
                        key={star}
                        className={classes.starIcon}
                        onClick={() => handleStarClick(star)}
                      />
                    ) : (
                      <StarBorderIcon
                        key={star}
                        className={classes.starIcon}
                        onClick={() => handleStarClick(star)}
                      />
                    )
                  )}
                </div>
              </Box>
            </div>
            <div>
              <ol className={classes.pointsList}>
                {packageDetails?.services?.map((service) => (
                  <li>
                    {service?.name}
                    <ul>
                      {service?.list.map((list) => (
                        <li>{list}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </Paper>
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              lineHeight: "34px",
              paddingRight: "10px",
              color: "#4caf50",
            }}
          >
            Price: ${getBasicPrice(packageDetails?.plans)}
          </span>{" "}
          |
          <Button
            color="primary"
            sx={{ fontWeight: "bold" }}
            component="a"
            onClick={() => handleOpenSubscribeModal(packageDetails)}
          >
            Subscribe
          </Button>{" "}
          |
          <Button
            color="primary"
            sx={{ fontWeight: "bold" }}
            component="a"
            href="/customize"
          >
            Customize
          </Button>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Keywords</Typography>
          <div>
            <ul className={classes.pointsList}>
              {packageDetails?.keywords?.map((keyword, index) => (
                <li key={index}>{keyword}</li>
              ))}
            </ul>
          </div>
        </Box>
        <Box>
          <Typography variant="h5" style={{ marginTop: 20 }} gutterBottom>
            Frequently Asked Questions (FAQ)
          </Typography>

          {packageDetails?.faq?.map((faq, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">{faq?.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">{faq?.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box>
          {/* Modal component */}
          <SubscriptionModalComponent
            open={modalOpen}
            onClose={handleCloseModal}
            data={modalData}
          />
        </Box>
      </Container>
    </div>
  );
};

export default PackageDetailComponent;
